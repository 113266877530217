<template>
  <div class="outBox">
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <!-- 助力场景 -->
    <div class="help">
      <Mytitle
        :data="{
          h: '助力场景',
        }"
      ></Mytitle>
      <div class="help_two" v-if="selectId == 2">
        <div class="divs">
          <div class="adver_div">
            <img src="@/assets/scene/智慧屏场景/智慧屏场景_广告屏.png" alt="" />
            <p>
              广告屏
              <span class="line"></span>
            </p>
          </div>
          <div class="other_div">
            <img
              src="@/assets/scene/智慧屏场景/智慧屏场景_智能魔镜.png"
              alt=""
            />
            <p>
              智能魔镜
              <span class="line"></span>
            </p>
          </div>
          <div class="other_div">
            <img
              src="@/assets/scene/智慧屏场景/智慧屏场景_线下大屏.png"
              alt=""
            />
            <p>
              线下大屏
              <span class="line"></span>
            </p>
          </div>
        </div>
        <div class="mirror_div">
          <img src="@/assets/scene/智慧屏场景/智慧屏场景_美妆镜.png" alt="" />
          <p>
            美妆镜
            <span class="line"></span>
          </p>
        </div>
      </div>
      <div class="help_conter" v-else>
        <div class="item_div" v-for="(item, index) in showList" :key="index">
          <img :src="item.conterImg" alt="" />
          <p>
            {{ item.name }}
            <span class="line"></span>
          </p>
        </div>
      </div>
    </div>
    <!-- 产品服务 -->
    <div class="serve">
      <Mytitle
        :data="{
          h: '产品服务',
        }"
      ></Mytitle>

      <div class="ser_conter">
        <div class="item_div">
          <img src="@/assets/scene/icon_service_dingzhi@2x.png" alt="" /><span
            >定制服务</span
          >
        </div>
        <div class="item_div">
          <img
            src="@/assets/scene/icon_service_jishuzhichi@2x.png"
            alt=""
          /><span>技术支持</span>
        </div>
        <div class="item_div">
          <img src="@/assets/scene/icon_service_update@2x.png" alt="" /><span
            >版本升级服务</span
          >
        </div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="cloud">
      <Mytitle
        :data="{
          h: '合作案例',
        }"
      ></Mytitle>

      <div class="clo_img">
        <img src="@/assets/home/index/img_hezuo_1@2x.png" />
        <img src="@/assets/home/index/img_hezuo_2@2x.png" />
        <img src="@/assets/home/index/img_hezuo_3@2x.png" />
        <img src="@/assets/home/index/img_hezuo_4@2x.png" />
        <img src="@/assets/home/index/img_hezuo_5@2x.png" />
        <img src="@/assets/home/index/img_hezuo_6@2x.png" />
        <img src="@/assets/home/index/img_hezuo_7@2x.png" />
        <img src="@/assets/home/index/img_hezuo_8@2x.png" />
        <img src="@/assets/home/index/img_hezuo_9@2x.png" />
        <img src="@/assets/home/index/img_hezuo_10@2x.png" />
        <img src="@/assets/home/index/img_hezuo_11@2x.png" />
        <img src="@/assets/home/index/img_hezuo_12@2x.png" />
      </div>
    </div>
    <!-- 申请试用 -->
    <Try></Try>
  </div>
</template>
<script>
import Try from "@/components/Try/index"; //申请试用

import BigBgimg from "@/components/BigBgimg/index"; //大图
import Mytitle from "@/components/Mytitle/index"; //标题
export default {
  components: { Try, BigBgimg, Mytitle },
  data() {
    return {
      // 全部数据
      allList: [
        {
          top: {
            topTitle: "直播场景",
            topText:
              "【TiFancy美颜SDK】应用于娱乐直播、电商直播、教育直播、传媒直播等直播场景的互动玩法",
            topBtn: "联系我们",
            topBg: require("@/assets/scene/直播场景/应用场景_直播场景首图.png"),
          },
          conter: [
            {
              name: "娱乐直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_娱乐直播.png"),
            },
            {
              name: "电商直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_电商直播.png"),
            },
            {
              name: "教育直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_教育直播.png"),
            },
            {
              name: "活动直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_活动直播.png"),
            },
            {
              name: "新零售导购直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_新零售导购直播.png"),
            },
            {
              name: "培训直播",
              conterImg: require("@/assets/scene/直播场景/直播场景_培训直播.png"),
            },
          ],
        },
        {
          top: {
            topTitle: "视频场景",
            topText:
              "【TiFancy美颜SDK】应用于短视频、视频通话、视频编辑等视频场景的互动玩法",
            topBtn: "联系我们",
            topBg: require("@/assets/scene/视频场景/应用场景_视频场景首图.png"),
          },
          conter: [
            {
              name: "短视频",
              conterImg: require("@/assets/scene/视频场景/视频场景_短视频.png"),
            },
            {
              name: "视频通话",
              conterImg: require("@/assets/scene/视频场景/视频场景_视频通话.png"),
            },
            {
              name: "视频编辑",
              conterImg: require("@/assets/scene/视频场景/视频场景_视频编辑.png"),
            },
            {
              name: "小视频",
              conterImg: require("@/assets/scene/视频场景/视频场景_小视频.png"),
            },
            {
              name: "VLog剪辑",
              conterImg: require("@/assets/scene/视频场景/视频场景_vlog剪辑.png"),
            },
            {
              name: "视频会议",
              conterImg: require("@/assets/scene/视频场景/视频场景视频会议.png"),
            },
          ],
        },
        {
          top: {
            topTitle: "智慧屏场景",
            topText:
              "【TiFancy美颜SDK】应用于线下大屏、广告屏、美妆镜等不同屏幕场景的互动玩法",
            topBtn: "联系我们",
            topBg: require("@/assets/scene/智慧屏场景/应用场景_智慧屏场景首图.png"),
          },
        },
      ],
      // 显示数据
      bigData: null,
      showList: null,
      selectId: null,
    };
  },
  watch: {
    $route() {
      this.getShow();
    },
  },
  created() {
    this.getShow();
  },
  methods: {
    // 跳转显示
    getShow() {
      this.selectId = this.$route.query.id;
      let index = this.$route.query.id;
      let arr = this.allList[index];
      this.bigData = arr.top;
      this.showList = arr.conter;
    },
  },
};
</script>
<style lang='scss' scoped>
$width: 79.1%;
.outBox {
  width: 100%;
  font-family: Source Han Sans CN;
  // 助力场景
  .help {
    .help_conter,
    .help_two {
      width: $width;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      // 相同样式
      img {
        width: 100%;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
      }
      p {
        height: 45px;
        position: absolute;
        bottom: 16px;
        left: 16px;
        z-index: 9;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        .line {
          display: block;
          margin-top: 6px;
          width: 60px;
          height: 4px;
          background: #ffffff;
        }
      }
      .item_div {
        width: 31.5%;
        overflow: hidden;
        position: relative;
        margin-right: 30px;
        margin-bottom: 30px;
      }
      .item_div:last-child,
      .item_div:nth-child(3) {
        margin-right: 0;
      }
    }
    .help_two {
      justify-content: space-between;
      .divs {
        width: 65.7%;
        position: relative;
        .other_div {
          width: 47.9%;
          overflow: hidden;
          position: absolute;
        }
        .other_div:nth-child(1) {
          left: 0;
        }
        .other_div:nth-child(2) {
          right: 0;
        }
        .adver_div {
          width: 100%;
          overflow: hidden;
          position: relative;
          margin-bottom: 30px;
        }
      }
      .mirror_div {
        width: 31.5%;
        position: relative;
        overflow: hidden;
      }
    }
  }
  // 产品服务
  .serve {
    height: 300px;
    .ser_conter {
      display: flex;
      justify-content: space-around;
      text-align: center;
      .item_div {
        width: 33%;
        img {
          width: 15.7%;
        }
        span {
          display: block;
          height: 70px;
          line-height: 50px;
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }
  // 合作案例
  .cloud {
    background: #f5f5f5;
    padding-top: 1px;
    .clo_img {
      width: $width;
      margin: auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-bottom: 80px;
      img {
        width: 19.6%;
        margin: 20px 30px;
        box-shadow: 0px 0px 18px rgba(180, 180, 180, 0.46);
        transition: all 0.5s;
        cursor: pointer;
      }
      img:hover {
        box-shadow: 0px 0px 18px rgba(144, 177, 232, 0.46);
        transform: translateY(-10px);
      }
    }
  }
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>