var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outBox"},[_c('BigBgimg',{attrs:{"list":_vm.bigData}}),_c('div',{staticClass:"help"},[_c('Mytitle',{attrs:{"data":{
        h: '助力场景',
      }}}),(_vm.selectId == 2)?_c('div',{staticClass:"help_two"},[_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"help_conter"},_vm._l((_vm.showList),function(item,index){return _c('div',{key:index,staticClass:"item_div"},[_c('img',{attrs:{"src":item.conterImg,"alt":""}}),_c('p',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"line"})])])}),0)],1),_c('div',{staticClass:"serve"},[_c('Mytitle',{attrs:{"data":{
        h: '产品服务',
      }}}),_vm._m(2)],1),_c('div',{staticClass:"cloud"},[_c('Mytitle',{attrs:{"data":{
        h: '合作案例',
      }}}),_vm._m(3)],1),_c('Try')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divs"},[_c('div',{staticClass:"adver_div"},[_c('img',{attrs:{"src":require("@/assets/scene/智慧屏场景/智慧屏场景_广告屏.png"),"alt":""}}),_c('p',[_vm._v(" 广告屏 "),_c('span',{staticClass:"line"})])]),_c('div',{staticClass:"other_div"},[_c('img',{attrs:{"src":require("@/assets/scene/智慧屏场景/智慧屏场景_智能魔镜.png"),"alt":""}}),_c('p',[_vm._v(" 智能魔镜 "),_c('span',{staticClass:"line"})])]),_c('div',{staticClass:"other_div"},[_c('img',{attrs:{"src":require("@/assets/scene/智慧屏场景/智慧屏场景_线下大屏.png"),"alt":""}}),_c('p',[_vm._v(" 线下大屏 "),_c('span',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mirror_div"},[_c('img',{attrs:{"src":require("@/assets/scene/智慧屏场景/智慧屏场景_美妆镜.png"),"alt":""}}),_c('p',[_vm._v(" 美妆镜 "),_c('span',{staticClass:"line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ser_conter"},[_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/scene/icon_service_dingzhi@2x.png"),"alt":""}}),_c('span',[_vm._v("定制服务")])]),_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/scene/icon_service_jishuzhichi@2x.png"),"alt":""}}),_c('span',[_vm._v("技术支持")])]),_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/scene/icon_service_update@2x.png"),"alt":""}}),_c('span',[_vm._v("版本升级服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clo_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_1@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_2@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_3@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_4@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_5@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_6@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_7@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_8@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_9@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_10@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_11@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_12@2x.png")}})])
}]

export { render, staticRenderFns }